import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: any) => {
        if (err.status === 401) {
          this.localStorageService.clearStorage();
          this.router.navigate(['/auth/login']);
          return throwError(() => [{ message: '' }]);
        }

        const error = err?.error?.message
          ? [{ message: err?.error?.message }]
          : err?.error?.errors || err.statusText;

        return throwError(() => error);
      })
    );
  }
}
